$theme-colors: (
     "primary": #FE9717,
     "secondary": #1E1E1E,
     "danger":#EF3A3A


);
    

@import "~bootstrap/scss/bootstrap.scss";
@import "video-react/styles/scss/video-react";
